@import url('https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap');

.intro-block {
    height: 250px;
    padding-top: 150px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.intro-button {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    font-family: 'Quicksand';
    color: black;
    text-align: center;
    font-size: 15px;
    width: 175px;
    height: 50px;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: white;
}

.background {
    margin-left: 10vw;
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nh-image {
    width: 45%;
    height: 45%;
    border-radius: 20px;
    border-style: solid;
    border-width: 5px;
    border-color: white;
}

.about-title {
    font-family: 'Quicksand';
    font-size: 40px;
    text-align: center;
    width: 100vw;
    padding: 15px;
}

.head-of-house{
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-left: 50vw - 100px;
    padding: 20px;
}

.head-of-house img {
    width: 200px;
    height: 224px;
    border-radius: 10px;
}

.text-block {
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 10px;
    font-family: 'Quicksand';
    color: black;
}

.house-block {
    padding: 10px;
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.house {
    width: 300px;
    height: 275px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    margin: 10px;
    text-align: center;
    text-decoration: none;
    color: black;
    font-family: 'Quicksand';
}

.house:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: grey;
}

.house img {
    padding: 10px;
}

.divider {
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 20px;
}