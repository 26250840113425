@import url(https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap);

.NavBar-container {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.NavBar-sidebar {
  display: flex;
  padding: 10px 0 20px 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.7);
  height: auto;
  width: 100vw;
  top: 0;
  z-index: 1;
  justify-content: space-evenly;
  justify-items: center;
  flex-direction: row;
}

.NavBar-title-container {
  /* color: rgb(73, 73, 73); */
  width: 225px;
  height: auto;
  /* background-color: rgba(255, 255, 255, 0.5); */
  border-radius: 0px 0px 10px 10px;
  text-align: center;
  /* border-color: black; */
  z-index: 1;
}
.NavBar-title {
  /* color: rgb(73, 73, 73); */
  color: black;
  font-size: 30px;
  /* margin-top: 5px; */
  padding: 8px;
  font-family: 'Chivo', bold;
}

.NavBar-title .title-link {
  color: black;
  text-shadow: 3px 3px white;
  text-decoration: none;
  font-size: 50px;
  font-family: 'Quicksand', bold;
}


.logout-button {
  margin-top: 10px;
  margin-right: 10px;
}
  
.menu-item {
  padding: 8px;
  margin-top:15px;
}

.menu-item-current {
  padding: 10px;
}

.menu-item-current ::before {
  content: "⬤";
}

/* .menu-item ::before {
  content: "🐠";
  } */


.NavBar-link,
.NavBar-link:visited {
  /* color: rgb(77, 77, 77); */
  color: black;
  opacity: 0.8;
  text-decoration: none;
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  margin-left: 10px;
}


.NavBar-link:hover {
  opacity: 0.8;
  font-weight: bold;
}

/* .NavBar-link:active {
  color:blue;
} */

.NavBar-link.NavBar-login {
  opacity: 1;
}

.NavBar-link + .NavBar-link {
  margin-left: var(--m);
}
.footer-container {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 200px;
    text-align: center;
    font-family: 'Quicksand';
}
/**
* Welcome to your ✨𝔲𝔱𝔦𝔩𝔦𝔱𝔶 𝔰𝔱𝔶𝔩𝔢𝔰✨!
* This file includes utility classes that are super simple
* and can be used to add general styles; variable definitions
* for colors; and styles to html, body, and other high level
* DOMs.
* 
* All utility classes start with a `u-` and all do
* one basic CSS thing (for example, making the font-weight
* 600 for bolding) or are super generic.
* 
* This is 𝙉𝙊𝙏 the place to define classes for components or
* do rigorous styling. You shoud not need to change this file
* much after initial creation.
*/

/* TODO: REPLACE WITH YOUR OWN FONT! */

:root {
  --primary: #396dff;
  --primary--dim: #6987db;
  --darkgrey: #d4d4d4;
  --medgrey: #e0e0e0;
  --grey: #f7f7f7;
  --white: #fff;

  --xs: 4px;
  --s: 8px;
  --m: 16px;
  --l: 24px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

form {
  flex-grow: 1;
}

.u-flex {
  display: flex;
}

.u-flexColumn {
  display: flex;
  flex-direction: column;
}

.u-flex-justifyCenter {
  justify-content: center;
}

.u-flex-alignCenter {
  align-items: center;
}

.u-inlineBlock {
  display: inline-block;
}

.u-bold {
  font-weight: 600;
}

.u-textCenter {
  text-align: center;
}

.u-relative {
  position: relative;
}

.u-pointer {
  cursor: pointer;
}

.u-link {
  color: #396dff;
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

.u-link:hover {
  color: #6987db;
  color: var(--primary--dim);
}

.closing-block {
  padding-bottom: 100px;
}

.App-container {
    width: 100vw;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-height: 100vh;
    background-image: url(/static/media/minimalist-snow.0befea98.jpg);
    overflow: hidden;
    background-color: rgba(238, 238, 238, 0.90) !important;
    background-size: fill;
    background-blend-mode: color;
}
.intro-block {
    height: 250px;
    padding-top: 150px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.intro-button {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    font-family: 'Quicksand';
    color: black;
    text-align: center;
    font-size: 15px;
    width: 175px;
    height: 50px;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: white;
}

.background {
    margin-left: 10vw;
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nh-image {
    width: 45%;
    height: 45%;
    border-radius: 20px;
    border-style: solid;
    border-width: 5px;
    border-color: white;
}

.about-title {
    font-family: 'Quicksand';
    font-size: 40px;
    text-align: center;
    width: 100vw;
    padding: 15px;
}

.head-of-house{
    width: 100vw;
    display: flex;
    justify-content: center;
    margin-left: 50vw - 100px;
    padding: 20px;
}

.head-of-house img {
    width: 200px;
    height: 224px;
    border-radius: 10px;
}

.text-block {
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 10px;
    font-family: 'Quicksand';
    color: black;
}

.house-block {
    padding: 10px;
    width: 80vw;
    margin-left: 10vw;
    margin-right: 10vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.house {
    width: 300px;
    height: 275px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    margin: 10px;
    text-align: center;
    text-decoration: none;
    color: black;
    font-family: 'Quicksand';
}

.house:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: grey;
}

.house img {
    padding: 10px;
}

.divider {
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 20px;
}
.faq-container {
    padding-top: 150px;
    width: 60vw;
}

.faq-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.4);
    width: 100%;
    margin-left: 20vw;
    border-radius: 10px;
    margin-top: 30px;
}

.faq-q {
    color: black;
    font-family: 'Quicksand';
    font-size: 15px;
}

.faq-a {
    margin-top: 20px;
    color: gray;
    font-family: 'Quicksand';
    font-size: 15px;
}

.contact-container {
    padding-top: 150px;
}
.facilities-container {
    padding-top: 150px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.f-item {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Quicksand';
    align-items: center;
}

.f-item img {
    max-width: 30vw;
    border-radius: 20px;
    border-style: solid;
    border-width: 5px;
    border-color: white;

}

.f-item p {
    max-width: 30vw;
    margin-left: 10px;
    text-align: center;
}

.f-extra {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Quicksand';
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
}

.f-extra a{
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px;
    text-decoration: none;
    font-family: 'Quicksand';
    color: black;
    text-align: center;
    font-size: 15px;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: white;
}
.gov-container {
    padding-top: 150px;
    
}

.gov-gov-container {
    width: 50vw;
    margin-left: 25vw;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    font-family: 'Quicksand';
    border-style: solid;
    border-color: white;
    border-radius: 20px;
    margin-bottom: 80px;
}

.gov-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    font-family: 'Quicksand';
    
}

.gov-row div{
    width: 200px;
    
}

.gov-row a{
    text-decoration: none;
    color: black;
}

.gov-row a:hover{
    color: white;
}

.gov-title {
    font-family: 'Quicksand';
    font-size: 40px;
    text-align: center;
    padding: 15px;
}

.bullet {
    margin-left: 20px;
    padding: 5px;
}

.bullet:before {
    content: '•  ';
}

.bullet-2 {
    margin-left: 60px;
    padding: 5px;
}

.bullet-2:before {
    content: '‣  ';
}

.g-extra {
    width: 50vw;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Quicksand';
    align-items: center;
}

.g-title {
    font-family: 'Quicksand';
    font-size: 40px;
    text-align: center;
    padding: 15px;
}

.g-extra a{
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    font-family: 'Quicksand';
    color: black;
    text-align: center;
    font-size: 15px;
    width: 175px;
    height: 50px;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: white;
}

