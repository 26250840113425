@import url('https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap');


.facilities-container {
    padding-top: 150px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.f-item {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Quicksand';
    align-items: center;
}

.f-item img {
    max-width: 30vw;
    border-radius: 20px;
    border-style: solid;
    border-width: 5px;
    border-color: white;

}

.f-item p {
    max-width: 30vw;
    margin-left: 10px;
    text-align: center;
}

.f-extra {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Quicksand';
    align-items: center;
    gap: 30px;
}

.f-extra a{
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px;
    text-decoration: none;
    font-family: 'Quicksand';
    color: black;
    text-align: center;
    font-size: 15px;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: white;
}