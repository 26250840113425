@import url('https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap');

.faq-container {
    padding-top: 150px;
    width: 60vw;
}

.faq-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.4);
    width: 100%;
    margin-left: 20vw;
    border-radius: 10px;
    margin-top: 30px;
}

.faq-q {
    color: black;
    font-family: 'Quicksand';
    font-size: 15px;
}

.faq-a {
    margin-top: 20px;
    color: gray;
    font-family: 'Quicksand';
    font-size: 15px;
}
