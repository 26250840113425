@import url('https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap');


.footer-container {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 200px;
    text-align: center;
    font-family: 'Quicksand';
}