@import url('https://fonts.googleapis.com/css?family=Croissant+One|Open+Sans|Open+Sans+Condensed:300|Chivo|Quicksand&display=swap');

.gov-container {
    padding-top: 150px;
    
}

.gov-gov-container {
    width: 50vw;
    margin-left: 25vw;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    height: fit-content;
    font-family: 'Quicksand';
    border-style: solid;
    border-color: white;
    border-radius: 20px;
    margin-bottom: 80px;
}

.gov-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    font-family: 'Quicksand';
    
}

.gov-row div{
    width: 200px;
    
}

.gov-row a{
    text-decoration: none;
    color: black;
}

.gov-row a:hover{
    color: white;
}

.gov-title {
    font-family: 'Quicksand';
    font-size: 40px;
    text-align: center;
    padding: 15px;
}

.bullet {
    margin-left: 20px;
    padding: 5px;
}

.bullet:before {
    content: '•  ';
}

.bullet-2 {
    margin-left: 60px;
    padding: 5px;
}

.bullet-2:before {
    content: '‣  ';
}

.g-extra {
    width: 50vw;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Quicksand';
    align-items: center;
}

.g-title {
    font-family: 'Quicksand';
    font-size: 40px;
    text-align: center;
    padding: 15px;
}

.g-extra a{
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
    font-family: 'Quicksand';
    color: black;
    text-align: center;
    font-size: 15px;
    width: 175px;
    height: 50px;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color: white;
}
